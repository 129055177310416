// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-lekoarts-gatsby-theme-cara-src-templates-cara-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-cara/src/templates/cara.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-cara-src-templates-cara-tsx" */),
  "component---src-pages-owner-create-row-tsx": () => import("./../../../src/pages/owner/create/row.tsx" /* webpackChunkName: "component---src-pages-owner-create-row-tsx" */),
  "component---src-pages-recipes-index-tsx": () => import("./../../../src/pages/recipes/index.tsx" /* webpackChunkName: "component---src-pages-recipes-index-tsx" */),
  "component---src-pages-wines-index-tsx": () => import("./../../../src/pages/wines/index.tsx" /* webpackChunkName: "component---src-pages-wines-index-tsx" */)
}

